import Dialog from 'quasar/src/plugins/dialog/Dialog.js';

import axios from "axios";
import dayjs from "dayjs";

import { useCodeStore } from "@/stores/codeStore"
import { useCustomerStore } from "@/stores/customerStore";

const $log = {
    debug: (_filename, ...args) => {
        $log.log('[DEBUG]', _filename, ...args);
    }
    , info: (_filename, ...args) => {
        $log.log('[INFO ]', _filename, ...args);
    }
    , error: (_filename, ...args) => {
        $log.log('[ERROR]', _filename, ...args);
    }
    , log: (_prefix, _filename, ...args) => {
        if (!$utils.isBlank(_filename)) {
            _prefix += ' ' + _filename + ' -';
        }

        switch (args.length) {
            case 1:
                console.log(_prefix, args[0]);
                break;
            case 2:
                console.log(_prefix, args[0], args[1]);
                break;
            case 3:
                console.log(_prefix, args[0], args[1], args[2]);
                break;
            case 4:
                console.log(_prefix, args[0], args[1], args[2], args[3]);
                break;
            default:
                console.log(_prefix, ...args);
        }
    }
};

const $utils = {
    consult_start_hour: 10
    , consult_end_hour: 18
    , deepCopy: (_jsonObject) => {
        return JSON.parse(JSON.stringify(_jsonObject));
    }
    , isNull: (_obj) => {
        return _obj === undefined || _obj === 'undefined' || _obj === null || _obj === 'null';
    }
    , isNotNull: (_obj) => {
        return !$utils.isNull(_obj);
    }
    , isBlank: (_obj) => {
        return _obj === undefined || _obj === 'undefined' || _obj === null || _obj === 'null' || String(_obj).trim() === '';
    }
    , isNotBlank: (_obj) => {
        return !$utils.isBlank(_obj);
    }
    , isEmpty: (_obj) => {
        return $utils.isBlank(_obj) || ( _obj != null && typeof _obj == "object" && !Object.keys(_obj).length );
    }
    , extractNumbers(inputString) {
        if (inputString.endsWith('조')) {
            inputString += '00000000';
        } else if (inputString.endsWith('억')) {
            inputString += '0000';
        } else if (inputString.endsWith('조', inputString.length - 1)) {
            inputString += '00000000';
        } else if (inputString.endsWith('억', inputString.length - 1)) {
            inputString += '0000';
        }

        return inputString.replace(/\D/g, '');
    }
    , currencyToKorean(_number) {
        _number = $utils.extractNumbers(_number.toString()); // 숫자만 남김
        _number = Number(_number) * 10000; // 만원단위까지만 입력하는 것을 고려

        const koreanUnits = ['조', '억', '만', ''];
        const unit = 10000;
        let result = '';

        for (let i = 3; i >=0; i --) {
            let mod = _number % unit;
            let modToString;

            _number = Math.floor(_number / unit);

            if (mod !== 0) {
                modToString = mod.toString().replace(/(\d)(\d{3})/, '$1,$2');
            }

            if (modToString !== undefined) {
                result = modToString + koreanUnits[i] + result;
            }
        }

        return result;
    }
    , getUrl() {
        let rUrl = location.protocol + '//' + location.hostname;

        if (eval(location.port)) {
            rUrl += ':' + location.port;
        }

        return rUrl;
    }
    , makeDatetime: (_date, _hour, _min, _sec) => {
        let datetime = _date;

        if (_hour === undefined) {
            datetime += 'T00'
        } else {
            datetime += 'T' + _hour;
        }

        if (_min === undefined) {
            datetime += ':00';
        } else {
            datetime += ':' + _min;
        }

        if (_sec === undefined) {
            datetime += ':00';
        } else {
            datetime += ':' + _sec;
        }

        return datetime;
    }
    , getConsultStartHour(_isToday) {
        let startHour = $utils.consult_start_hour;

        // 현재 시간이 상담 시간 이내라면, 현재 시간을 시작시간으로 한다.
        if (dayjs().hour() > $utils.consult_start_hour
                && dayjs().hour() < $utils.consult_end_hour) {
            startHour = dayjs().hour();

            // 현재 시간이 30분을 넘었으면, 한시간을 더한 시간을 시작시간으로 한다.
            if (dayjs().minute() > 30) {
                startHour = startHour + 1;
            }
        }

        // 계산된 시작 시간이 종료 시간보다 크다면, 기본 시작시간을 시작시간으로 한다.
        if (startHour >= $utils.consult_end_hour || !_isToday) {
            startHour = $utils.consult_start_hour;
        }

        return startHour;
    }
};

const $client = {
    headers: () => {
        return {
            authorization: 'Bearer ' + useCustomerStore().accessToken
        }
    }
    , get: async (_uri, _param, _success, _error) => {
        let queryStr = '';

        if ($utils.isNotNull(_param)) {
            queryStr = new URLSearchParams(_param).toString();
        }

        if ($utils.isNotBlank(queryStr)) {
            _uri = _uri + '?' + queryStr;
        }

        await axios.get(_uri, { headers: $client.headers() }).then(response => {
            if ($utils.isNotNull(response) && $utils.isNotNull(response.data)) {
                $log.debug('', _uri, _param, response.data.res_code, response.data.res_msg);

                if ($utils.isNotNull(_success)) {
                    _success(response.data.data);
                }
            } else {
                if ($utils.isNotNull(_success)) {
                    _success(null);
                }
            }
        }).catch(async error => {
            await $client.handleError('GET', error, _uri, _param, _success, _error)
        });
    }
    , post: async (_uri, _param, _success, _error) => {
        if ($utils.isNull(_param)) {
            _param = {};
        }

        await axios.post(_uri, _param, { headers: $client.headers() }).then(response => {
            if ($utils.isNotNull(response) && $utils.isNotNull(response.data)) {
                $log.debug('', _uri, _param, response.data.res_code, response.data.res_msg);

                if ($utils.isNotNull(_success)) {
                    _success(response.data.data);
                }
            } else {
                if ($utils.isNotNull(_success)) {
                    _success(null);
                }
            }
        }).catch(async error => {
            await $client.handleError('POST', error, _uri, _param, _success, _error)
        })
    }
    , handleError: async (_type, error, _uri, _param, _success, _error) => {
        if ($utils.isNull(error.response) || $utils.isNull(error.response.data)) {
            $log.error('utils.js: 213', _uri, _param, error);
        } else if ($utils.isNotBlank(error.response.data.res_code)) {
            $log.error('utils.js: 215', _uri, _param, error.response.data.res_code, error.response.data.res_msg);

            // Access Token 만료
            if (error.response.data.res_code === 'common.003') {
                let param = {
                    customer_id: useCustomerStore().customerInfo.customer_id
                    , access_token: useCustomerStore().accessToken
                };

                await $client.post('/svc/refresh', param, async (response) => {
                    useCustomerStore().accessToken = response.access_token;

                    if (_type === 'GET') {
                        await $client.get(_uri, _param, _success, _error);
                    } else {
                        await $client.post(_uri, _param, _success, _error);
                    }
                });
            } else {
                if (error.response.data.res_code === '401' // Access Token 이 없음
                        || error.response.data.res_code === 'common.001' // Access Token 형식 오류
                        || error.response.data.res_code === 'common.002' // Access Token 만료
                        || error.response.data.res_code === '613' // 다른 Refresh Token
                        || error.response.data.res_code === '614' // 다른 Access Token
                ) {
                    useCustomerStore().$reset();
                    window.location.href = '/login';
                }

                await $dialog.alert(error.response.data.res_msg, () => {
                    if ($utils.isNotNull(_error)) {
                        _error(error.response.data);
                    }
                });
            }
        } else {
            $log.error('utils.js: 239', _uri, _param, error);
        }
    }
    , postDirect: async (_uri, _param) => {
        if ($utils.isNull(_param)) {
            _param = {};
        }

        return await axios.post(_uri, _param, { headers: $client.headers() });
    }
};

const $code = {
    saveCodeData: async () => {
        let param = {
            display_yn: 'Y'
        };

        await $client.post('/svc/code/list', param, function(response) {
            useCodeStore().setCodeInfo(response);
        });
    }
    , getCodeData: async (_val, _unshiftOption) => {
        let codeStore = useCodeStore();

        if ($utils.isEmpty(codeStore.codeInfo)) {
            await $code.saveCodeData();
        }

        if (dayjs().diff(dayjs(codeStore.timestamp), 'minute') > 10) {
            await $code.saveCodeData();
        }

        let responseData = $utils.deepCopy(codeStore.getCodeInfo(_val.toUpperCase()));

        if ($utils.isNotNull(responseData) && _unshiftOption) {
            responseData.unshift({ cmn_code: '', code_name: '선택' });
        }

        return responseData;
    }
    , codeToOptions: (_codeDataList) => {
        let options = [];

        for (let item of _codeDataList) {
            options.push({
                value: item.cmn_code
                , label: item.code_name
            });
        }

        return options;
    }
    , getCodeName: async (_parentCode, _cmnCode) => {
        let codeName = '';
        let codeList = await $code.getCodeData(_parentCode, false);

        for (let item of codeList) {
            if (item.cmn_code === _cmnCode) {
                codeName = item.code_name;

                break;
            }
        }

        return codeName;
    }
};

const $dialog = {
    alert: async (_msg, _okFunc) => {
        Dialog.create({
            title: "알림",
            message: _msg,
            html: true,
            persistent: true,
            ok: {
                color: "primary"
            }
        }).onOk(() => {
            if ($utils.isNotNull(_okFunc)) {
                _okFunc();
            }
        });
    }
    , confirm: async (_msg, _okFunc, _cancelFunc) => {
        Dialog.create({
            title: "알림",
            message: _msg,
            html: true,
            persistent: true,
            cancel: {
                color: "secondary"
            },
            ok: {
                color: "primary"
            }
        }).onCancel(() => {
            if ($utils.isNotNull(_cancelFunc)) {
                _cancelFunc();
            }
        }).onOk(() => {
            if ($utils.isNotNull(_okFunc)) {
                _okFunc();
            }
        });
    }
};

const $kcb_cert = {
    needCert: async () => {
        let needCert = true;

        await $client.get('/svc/kcb/cert/need', null, function(response) {
            needCert = response;
        }, function(error) {
            $log.debug(error);
        });

        return needCert;
    }
    , openKcbCert: async (_kcbCertCall) => {
        // KCB 휴대폰 본인인증 모듈 초기화 후, 인증 Popup
        await $client.post('/svc/kcb/cert/prepare', { return_url: $utils.getUrl() }, function(response) {
            if (response.RSLT_CD === 'B000') {
                sessionStorage.setItem('kcb_cert_call', _kcbCertCall);

                let form = document.createElement('form');

                form.setAttribute('method', 'POST');
                form.setAttribute('action', 'https://safe.ok-name.co.kr/CommonSvl');

                document.body.appendChild(form);

                let input = document.createElement('input');

                input.setAttribute('type', 'hidden');
                input.setAttribute('name', 'tc');
                input.setAttribute('value', 'kcb.oknm.online.safehscert.popup.cmd.P931_CertChoiceCmd'); // 변경불가

                form.appendChild(input);

                input = document.createElement('input');

                input.setAttribute('type', 'hidden');
                input.setAttribute('name', 'cp_cd');
                input.setAttribute('value', response.CP_CD);

                form.appendChild(input);

                input = document.createElement('input');

                input.setAttribute('type', 'hidden');
                input.setAttribute('name', 'mdl_tkn');
                input.setAttribute('value', response.MDL_TKN);

                form.appendChild(input);

                form.submit();
            } else {
                $dialog.alert(response.RSLT_MSG);
            }
        });
    }
    , result: async (_mdlTkn, _success, _failure) => {
        let kcbParam = {
            MDL_TKN: _mdlTkn
        };

        if ($utils.isBlank(useCustomerStore().accessToken)) {
            await $client.post('/svc/kcb/cert/result', kcbParam, _success, _failure);
        } else {
            await $client.post('/svc/kcb/cert/result/customer', kcbParam, _success, _failure);
        }
    }
};

export { $log, $utils, $client, $code, $dialog, $kcb_cert };
